import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import React from 'react';
import ProjectCard from './ProjectCard';

export default function ProjectList() {
  const data = useStaticQuery(graphql`
    query {
      robotFace: file(relativePath: { eq: "unicorn-emoji.png" }) {
        childImageSharp {
          fixed(width: 24) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      tickets: file(relativePath: { eq: "movie-ticket-watcher-api-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 160) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      cultBooker: file(relativePath: { eq: "cult-booker-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 260) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      ypsCliShot: file(relativePath: { eq: "yps-cli.png" }) {
        childImageSharp {
          fluid(maxWidth: 160) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      seriesNotifier: file(relativePath: { eq: "series-notifier-site-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      golang: file(relativePath: { eq: "golang.png" }) {
        childImageSharp {
          fixed(height: 20) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      nodejs: file(relativePath: { eq: "nodejs-icon.png" }) {
        childImageSharp {
          fixed(height: 20) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      typescript: file(relativePath: { eq: "typescript-icon.png" }) {
        childImageSharp {
          fixed(height: 20) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      gatsby: file(relativePath: { eq: "gatsby-icon.png" }) {
        childImageSharp {
          fixed(width: 25) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      react: file(relativePath: { eq: "react-icon.png" }) {
        childImageSharp {
          fixed(height: 25) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      preact: file(relativePath: { eq: "preact-icon.png" }) {
        childImageSharp {
          fixed(height: 25) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      netlify: file(relativePath: { eq: "netlify-icon.png" }) {
        childImageSharp {
          fixed(width: 25) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      serverless: file(relativePath: { eq: "serverless-icon.png" }) {
        childImageSharp {
          fixed(height: 20) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      aws: file(relativePath: { eq: "aws-icon.png" }) {
        childImageSharp {
          fixed(height: 20) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      npm: file(relativePath: { eq: "npm-icon.png" }) {
        childImageSharp {
          fixed(height: 20) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      messenger: file(relativePath: { eq: "messenger-icon.png" }) {
        childImageSharp {
          fixed(height: 20) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const gatsby = (
    <OutboundLink href="https://www.gatsbyjs.org" target="_blank" title="Gatsby">
      <Img fixed={data.gatsby.childImageSharp.fixed} alt="gatsby logo" />
    </OutboundLink>
  );
  const react = (
    <OutboundLink href="https://reactjs.org" target="_blank" title="React">
      <Img fixed={data.react.childImageSharp.fixed} alt="react logo" />
    </OutboundLink>
  );
  const netlify = (
    <OutboundLink href="https://netlify.com" target="_blank" title="Netlify">
      <Img fixed={data.netlify.childImageSharp.fixed} alt="netlify logo" />
    </OutboundLink>
  );
  const goLang = (
    <OutboundLink href="https://golang.org" target="_blank" title="GO">
      <Img fixed={data.golang.childImageSharp.fixed} alt="go logo" />
    </OutboundLink>
  );
  const nodejs = (
    <OutboundLink href="https://nodejs.org" target="_blank" title="Node.js">
      <Img fixed={data.nodejs.childImageSharp.fixed} alt="node.js logo" />
    </OutboundLink>
  );
  const typescript = (
    <OutboundLink href="https://www.typescriptlang.org" target="_blank" title="TypeScript">
      <Img fixed={data.typescript.childImageSharp.fixed} alt="typescript logo" />
    </OutboundLink>
  );
  const serverless = (
    <OutboundLink href="https://serverless.com" target="_blank" title="Serverless">
      <Img fixed={data.serverless.childImageSharp.fixed} alt="serverless logo" />
    </OutboundLink>
  );
  const aws = (
    <OutboundLink href="https://aws.amazon.com" target="_blank" title="AWS">
      <Img fixed={data.aws.childImageSharp.fixed} alt="aws logo" />
    </OutboundLink>
  );
  const npm = (packageName: string) => (
    <OutboundLink
      href={`https://www.npmjs.com/package/${packageName}`}
      target="_blank"
      title={packageName}
    >
      <Img fixed={data.npm.childImageSharp.fixed} alt={packageName} />
    </OutboundLink>
  );
  const messenger = (
    <OutboundLink href="https://m.me/snbot" target="_blank" title="Facebook Messenger">
      <Img fixed={data.messenger.childImageSharp.fixed} alt="facebook messenger logo" />
    </OutboundLink>
  );

  const preact = (
    <OutboundLink href="https://preactjs.com" target="_blank" title="Preact">
      <Img fixed={data.preact.childImageSharp.fixed} alt="preact logo" />
    </OutboundLink>
  );
  return (
    <div id="side-projects">
      <div className="col-xs-12 col-sm-12 col-lg-12 card-heading">
        <Link to="/resume#side-projects" className="page-section-link">
          <h3 className="title title-resume">
            <Img fixed={data.robotFace.childImageSharp.fixed} />
            &nbsp;Side Projects
          </h3>
        </Link>
      </div>
      <div className="clearfix" />
      <div className="row row-eq-height">
        <ProjectCard
          name="Movie Ticket Watcher"
          link="https://movie-ticket-watcher.jaydp.com"
          projectArtwork={data.tickets.childImageSharp.fluid}
          frontendTech={() => (
            <>
              {gatsby}
              {react}
              {netlify}
            </>
          )}
          backendTech={() => (
            <>
              {goLang}
              {serverless}
              {aws}
            </>
          )}
        >
          Looks for a particular movie in a particular cinema &amp; notifies you as soon as tickets
          for it go live
        </ProjectCard>

        <ProjectCard
          name="Cult.fit Workout booker"
          link="https://github.com/jaydp17/cultfit-class-booker"
          projectArtwork={data.cultBooker.childImageSharp.fluid}
          frontendTech={() => <>N/A</>}
          backendTech={() => (
            <>
              {goLang}
              {serverless}
              {aws}
            </>
          )}
        >
          It's a simple cron that goes ahead &amp; books a workout for you in your preferred center.
          Workout type &amp; centers are customizable.
        </ProjectCard>
      </div>

      <div className="row row-eq-height">
        <ProjectCard
          name="YouTube Playlist Sorter"
          link="https://github.com/jaydp17/youtube-playlist-sorter"
          projectArtwork={data.ypsCliShot.childImageSharp.fluid}
          backendLabel="CLI (command line interface):"
          backendTech={() => (
            <>
              {nodejs}
              {npm('youtube-playlist-sorter')}
            </>
          )}
        >
          It's a CLI app (
          <OutboundLink href="https://www.npmjs.com/package/youtube-playlist-sorter">
            available on npm
          </OutboundLink>
          ) written in Node.js to sort ↕️ videos in a public playlist based on its popularity 🔥
        </ProjectCard>

        <ProjectCard
          name="Series Notifier"
          link="https://series-notifier.jaydp.com/"
          projectArtwork={data.seriesNotifier.childImageSharp.fluid}
          frontendTech={() => (
            <>
              {preact}
              {messenger}
            </>
          )}
          backendTech={() => (
            <>
              {typescript}
              {serverless}
              {aws}
            </>
          )}
        >
          A Facebook Messenger bot 🤖 that notifies you when your favorite TV Series (for example
          Game of Thrones, Big Bang Theory, etc.) releases 🚀 a new episode!
        </ProjectCard>
      </div>
    </div>
  );
}
