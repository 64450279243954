import Img, { FixedObject } from 'gatsby-image';
import React from 'react';

interface Props {
  title: string;
  company: string;
  description: React.FunctionComponent;
  logo: FixedObject;
  rank: string;
  date: string;
}
export default function RewardCard({
  title,
  company,
  description: Description,
  logo,
  rank,
  date,
}: Props) {
  return (
    <div className="col-xs-12 col-sm-12 col-lg-6" style={{ marginBottom: '2em' }}>
      <div className="block-grey" style={{ height: '100%' }}>
        <div className="row">
          <div className="col-xs-8">
            <div className="font-weight-m" style={{ color: '#f3bb49' }}>
              {rank}
            </div>
            <p className="font-weight-m" style={{ marginTop: '0px' }}>
              {title}
            </p>
            <div>
              {company}
              <br />
              <div className="duration font-weight-m">{date}</div>
            </div>
          </div>
          <div className="col-xs-4">
            <Img fixed={logo} className="pull-right" alt={`${company} logo`} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 rewards-description">
            <p>
              <Description />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
