import React from 'react';
import Layout from '../components/Layout';
import Projects from '../components/Projects';
import Rewards from '../components/Rewards';
import SEO from '../components/Seo';
import PageHeader from '../components/SubPageHeader';
import TechList from '../components/TechList';
import WorkExperience from '../components/WorkExperience';
import resumePng from '../images/resume.png';

export default function Resume() {
  return (
    <Layout>
      <SEO title="Resume | Jaydeep Solanki" />
      <PageHeader title={'Resume'} subTitle={'My Qualifications'} pageLogo={resumePng} />
      <section className="content padding-block border-bottom">
        <div className="container">
          <WorkExperience />
        </div>
      </section>

      <section className="content padding-block border-bottom">
        <div className="container">
          <Rewards />
        </div>
      </section>

      <section className="content padding-block border-bottom">
        <div className="container">
          <TechList />
        </div>
      </section>

      <section className="content padding-block border-bottom">
        <div className="container">
          <Projects />
        </div>
      </section>
    </Layout>
  );
}
