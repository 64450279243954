import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import React from 'react';

export default function TechList() {
  const data = useStaticQuery(graphql`
    query {
      robotFace: file(relativePath: { eq: "robot-face.png" }) {
        childImageSharp {
          fixed(width: 24) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      golang: file(relativePath: { eq: "golang.png" }) {
        childImageSharp {
          fixed(height: 60) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      nodejs: file(relativePath: { eq: "nodejs.png" }) {
        childImageSharp {
          fixed(height: 90) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      docker: file(relativePath: { eq: "docker.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      kubernetes: file(relativePath: { eq: "kubernetes-2.png" }) {
        childImageSharp {
          fixed(width: 120) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      react: file(relativePath: { eq: "reactjs.png" }) {
        childImageSharp {
          fixed(height: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);
  return (
    <div>
      <div className="col-xs-12 col-sm-12 col-lg-12 card-heading">
        <h3 className="title title-resume">
          <Img fixed={data.robotFace.childImageSharp.fixed} />
          &nbsp;Technologies
        </h3>
      </div>
      <div className="clearfix" />
      <div className="menu technologies">
        <div className="col-xs-12 col-sm-12 col-lg-12">
          <span className="menu-li">
            <OutboundLink href="https://golang.org" target="_blank">
              <Img fixed={data.golang.childImageSharp.fixed} alt="go" className="menu-img" />
            </OutboundLink>
          </span>
          <span className="menu-li">
            <OutboundLink href="https://nodejs.org" target="_blank">
              <Img fixed={data.nodejs.childImageSharp.fixed} alt="nodejs" className="menu-img" />
            </OutboundLink>
          </span>
          <span className="menu-li">
            <OutboundLink href="https://www.docker.com" target="_blank">
              <Img fixed={data.docker.childImageSharp.fixed} alt="docker" className="menu-img" />
            </OutboundLink>
          </span>
          <span className="menu-li">
            <OutboundLink href="https://kubernetes.io" target="_blank">
              <Img
                fixed={data.kubernetes.childImageSharp.fixed}
                alt="kubernetes"
                className="menu-img"
              />
            </OutboundLink>
          </span>
          <span className="menu-li">
            <OutboundLink href="https://reactjs.org" target="_blank">
              <Img fixed={data.react.childImageSharp.fixed} alt="react" className="menu-img" />
            </OutboundLink>
          </span>
        </div>
      </div>
    </div>
  );
}
