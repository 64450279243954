import Img, { FixedObject } from 'gatsby-image';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import React from 'react';

interface Props {
  position: string;
  company: string;
  companyUrl?: string;
  description: string;
  logo: FixedObject;
  startMonth: Date;
  endMonth: Date;
}
export default function ExperienceCard({
  position,
  company,
  companyUrl,
  description,
  logo,
  startMonth,
  endMonth,
}: Props) {
  return (
    <div className="col-xs-12 col-sm-12 col-lg-6" style={{ marginBottom: '2em' }}>
      <div className="block-grey" style={{ height: '100%' }}>
        <div className="row">
          <div className="col-xs-8">
            <p className="font-weight-m">{position}</p>
            <div>
              <CompanyName name={company} url={companyUrl} />
              <br />
              <div className="duration font-weight-m">{duration(startMonth, endMonth)}</div>
            </div>
          </div>
          <div className="col-xs-4">
            <Img fixed={logo} className="pull-right" alt={`${company} logo`} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <p>{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function CompanyName({ name, url }: { name: string; url?: string }) {
  if (url) {
    return (
      <OutboundLink href={url} target="_blank">
        {name}
      </OutboundLink>
    );
  }
  return <>{name}</>;
}

/**
 * Gives you a nice looking duration like May 2014 - July 2014 (3 months)
 */
function duration(d1: Date, d2: Date): string {
  const months = monthDiff(d1, d2);
  const durationStr = monthToYearsMonths(months);

  const fromTo = `${getDateString(d1)} - ${getDateString(d2)}`;
  return `${fromTo} (${durationStr})`;
}

function getDateString(d: Date) {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return `${monthNames[d.getMonth()]} ${d.getFullYear()}`;
}

/**
 * Computes the difference in two dates in months
 */
function monthDiff(d1: Date, d2: Date): number {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  months += Math.round((d2.getDate() - d1.getDay()) / 30);
  return months <= 0 ? 0 : months;
}

/**
 * Changes 13 months to (1year 1 month)
 */
function monthToYearsMonths(overallMonths: number): string {
  const years = Math.floor(overallMonths / 12);
  const months = Math.round(overallMonths % 12);
  const yearStrComp = `${years} year${years > 1 ? 's' : ''}`;
  const yearsStr = years > 0 ? yearStrComp : '';
  const monthStr = `${months} months`;
  return `${yearsStr} ${monthStr}`.trim();
}
