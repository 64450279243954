import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import ExperienceCard from './ExperienceCard';
import { deens, googleCodeIn, gsoc, hackerrank, rentomojo, yolobots } from './experiences';

export default function WorkExperience() {
  const data = useStaticQuery(graphql`
    query {
      expLogo: file(relativePath: { eq: "working-on-laptop.png" }) {
        childImageSharp {
          fixed(width: 24) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      deensLogo: file(relativePath: { eq: "deens-logo.png" }) {
        childImageSharp {
          fixed(width: 70) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      rentomojoLogo: file(relativePath: { eq: "rentomojo.png" }) {
        childImageSharp {
          fixed(width: 70) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      yolobotsLogo: file(relativePath: { eq: "yolobots.png" }) {
        childImageSharp {
          fixed(width: 70) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      hackerrankLogo: file(relativePath: { eq: "hackerrank.png" }) {
        childImageSharp {
          fixed(width: 70) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      googleLogo: file(relativePath: { eq: "google.png" }) {
        childImageSharp {
          fixed(width: 70) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);
  return (
    <div>
      <div className="col-xs-12 col-sm-12 col-lg-12 card-heading">
        <h3 className="title title-resume">
          <Img fixed={data.expLogo.childImageSharp.fixed} />
          &nbsp;Work Experience
        </h3>
      </div>
      <div className="clearfix" />
      <div className="row row-eq-height">
        <ExperienceCard {...deens} logo={data.deensLogo.childImageSharp.fixed} />
        <ExperienceCard {...rentomojo} logo={data.rentomojoLogo.childImageSharp.fixed} />
      </div>
      <div className="row row-eq-height">
        <ExperienceCard {...yolobots} logo={data.yolobotsLogo.childImageSharp.fixed} />
        <ExperienceCard {...hackerrank} logo={data.hackerrankLogo.childImageSharp.fixed} />
      </div>
      <div className="row row-eq-height">
        <ExperienceCard {...googleCodeIn} logo={data.googleLogo.childImageSharp.fixed} />
        <ExperienceCard {...gsoc} logo={data.googleLogo.childImageSharp.fixed} />
      </div>
    </div>
  );
}
