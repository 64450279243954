import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import RewardCard from './RewardCard';
import { amritaPitchFest, gcdc, imagineCup, prarambh, samsungUSID } from './rewards-list';

export default function Rewards() {
  const data = useStaticQuery(graphql`
    query {
      trophy: file(relativePath: { eq: "trophy.png" }) {
        childImageSharp {
          fixed(width: 24) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      runnerUp: file(relativePath: { eq: "2nd_prize.png" }) {
        childImageSharp {
          fixed(width: 70) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      thirdPrize: file(relativePath: { eq: "3rd_prize.png" }) {
        childImageSharp {
          fixed(width: 70) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      starPrize: file(relativePath: { eq: "star_prize.png" }) {
        childImageSharp {
          fixed(width: 70) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      firstPrize: file(relativePath: { eq: "1st_prize.png" }) {
        childImageSharp {
          fixed(width: 70) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);
  return (
    <div id="rewards">
      <div className="col-xs-12 col-sm-12 col-lg-12 card-heading">
        <h3 className="title title-resume">
          <Img fixed={data.trophy.childImageSharp.fixed} />
          &nbsp;Rewards
        </h3>
      </div>
      <div className="clearfix" />
      <div className="row row-eq-height">
        <RewardCard {...gcdc} logo={data.runnerUp.childImageSharp.fixed} />
        <RewardCard {...imagineCup} logo={data.thirdPrize.childImageSharp.fixed} />
      </div>
      <div className="row row-eq-height">
        <RewardCard {...samsungUSID} logo={data.thirdPrize.childImageSharp.fixed} />
        <RewardCard {...amritaPitchFest} logo={data.starPrize.childImageSharp.fixed} />
      </div>
      <div className="row row-eq-height">
        <RewardCard {...prarambh} logo={data.firstPrize.childImageSharp.fixed} />
      </div>
    </div>
  );
}
