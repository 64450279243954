import { Link } from 'gatsby';
import React from 'react';
import closeSvg from '../images/close.svg';

interface Props {
  title: string;
  subTitle: string;
  pageLogo: string;
}
const SubPageHeader = (props: Props) => {
  const { title, subTitle, pageLogo } = props;
  return (
    <header className="head">
      <div className="container">
        <div className="row">
          <div className="col-xs-8 col-sm-11 col-lg-11">
            <img className="logo-page" src={pageLogo} alt="page logo" width="70" height="70" />
            <h2 className="title">{title}</h2>
            <h4 className="sub-title">{subTitle}</h4>
          </div>
          <div className="col-xs-4 col-sm-1 col-lg-1 text-right">
            <Link to="/" className="btn-close hover-animate">
              <img src={closeSvg} width="100%" alt="close btn" />
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default SubPageHeader;
