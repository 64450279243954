import { OutboundLink } from 'gatsby-plugin-google-analytics';
import React, { FunctionComponent } from 'react';

export const gcdc = {
  title: 'Google Cloud Developer Challenge, India',
  company: 'Google',
  rank: 'Runner-up',
  date: '2013',
  description: (() => <></>) as FunctionComponent,
};

export const imagineCup = {
  title: 'Microsoft Imagine Cup, World Citizenship Category, India',
  company: 'Microsoft',
  rank: '2nd Runner-up',
  date: '2013',
  description: (() => (
    <span>
      Link:{' '}
      <OutboundLink href="http://bit.ly/29q9du2" target="_blank">
        Hindustan Times
      </OutboundLink>
    </span>
  )) as FunctionComponent,
};

export const samsungUSID = {
  title: 'Samsung USID Design Challenge, India',
  company: 'Samsung',
  rank: '2nd Runner-up',
  date: '2012',
  description: (() => <></>) as FunctionComponent,
};

export const amritaPitchFest = {
  title: 'AmritaTBI Pitch Fest 2015',
  company: 'AmritaTBI',
  rank: 'Winner',
  date: '2015',
  description: (() => <></>) as FunctionComponent,
};

export const prarambh = {
  title: 'IIM Udaipur Startup Challenge (Prarambh)',
  company: 'IIM Udaipur',
  rank: 'Winner',
  date: '2015',
  description: (() => <></>) as FunctionComponent,
};
