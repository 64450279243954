import Img, { FluidObject } from 'gatsby-image';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import React, { ReactNode } from 'react';

interface Props {
  name: string;
  link: string;
  frontendTech?: () => ReactNode;
  backendLabel?: string;
  backendTech?: () => ReactNode;
  projectArtwork: FluidObject & { presentationWidth: any };
  children: ReactNode;
}

export default function ProjectCard({
  name,
  link,
  projectArtwork,
  frontendTech,
  backendLabel = 'Backend:',
  backendTech,
  children,
}: Props) {
  return (
    <div className="col-xs-12 col-sm-12 col-lg-9" style={{ marginBottom: '2em' }}>
      <div className="block-grey" style={{ height: '100%' }}>
        <div className="row project">
          <div className="title-and-logos">
            <p className="project-title">
              <OutboundLink href={link} target="_blank" className="font-weight-m">
                {name}
              </OutboundLink>
            </p>
            {frontendTech || backendTech ? (
              <div className="tech-group">
                {frontendTech && (
                  <div className="duration font-weight-m tech-frontend">
                    Frontend:
                    <br />
                    <div className="tech-logos">{frontendTech()}</div>
                  </div>
                )}

                {backendTech && (
                  <div className="duration font-weight-m tech-backend">
                    {backendLabel}
                    <br />
                    <div className="tech-logos">{backendTech()}</div>
                  </div>
                )}
              </div>
            ) : null}
          </div>
          <div className="artwork">
            <OutboundLink href={link} target="_blank">
              <Img
                fluid={projectArtwork}
                style={{
                  width: '100%',
                  maxWidth: projectArtwork.presentationWidth,
                  margin: '0 auto', // Used to center the image
                }}
                className="pull-right"
                alt="project artwork"
              />
            </OutboundLink>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <p>{children}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
