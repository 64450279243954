export const deens = {
  position: 'The only Backend Developer',
  company: 'Deens.com',
  companyUrl: 'https://deens.com',
  startMonth: new Date(2018, 5, 4),
  endMonth: new Date(),
  description:
    'Deens.com is a platform where you can explore/customize & book the best trips across the globe created by local experts.' +
    ' I am the only Backend Developer who works on the Serverless backend written in Node.js (TypeScript) & Go. I also, occasionally update a few things on the frontend (React.js)',
};

export const rentomojo = {
  position: 'Backend Lead',
  company: 'RentoMojo',
  companyUrl: 'https://www.rentomojo.com',
  startMonth: new Date(2016, 7, 16),
  endMonth: new Date(2018, 4, 30),
  description:
    'I worked on the backend as well as DevOps. I contributed in building' +
    ' Microservices written in Node.js/TypeScript which run on either AWS Lambda' +
    ' or Kubernetes. I also manage the AWS & Azure infrastructure along with the CI/CD pipeline',
};

export const yolobots = {
  position: 'Co Founder',
  company: 'Yolobots',
  companyUrl: 'https://www.yolobots.com',
  startMonth: new Date(2015, 5, 1),
  endMonth: new Date(2016, 7, 15),
  description:
    'My role includes developing an Android app & a server that could serve the' +
    ' app.\nAnalysing how users are using the app & figuring out drop points.\n' +
    'I also had a small hand in marketing the product',
};

export const hackerrank = {
  position: 'Intern',
  company: 'HackerRank',
  companyUrl: 'https://www.hackerrank.com',
  startMonth: new Date(2014, 4, 1),
  endMonth: new Date(2014, 6, 30),
  description:
    'For the major part of the internship I worked on creating a code stub ' +
    'generator, that generates boilerplate code in 10 different languages for various data ' +
    'structures like (like. DoublyLinked List, BinaryTree, 2D array, etc.) for any given ' +
    'problem on Hackerrank.',
};

export const googleCodeIn = {
  position: 'Mentor',
  company: 'Google Code-In',
  companyUrl: 'https://developers.google.com/open-source/gci/',
  startMonth: new Date(2013, 9, 1),
  endMonth: new Date(2014, 1, 30),
  description:
    'Google Code-in is a contest to introduce pre-university students ' +
    '(ages 13-17) to open source software development.\nHere I mentored a student to add ' +
    "features into Audio Player used in Okular (KDE's document viewer) for playing " +
    'embedded sounds.',
};

export const gsoc = {
  position: 'Developer',
  company: 'Google Summer of Code',
  companyUrl: 'https://summerofcode.withgoogle.com/',
  startMonth: new Date(2013, 4, 1),
  endMonth: new Date(2013, 7, 30),
  description:
    'Google Summer of Code is a global program focused on bringing more student' +
    " developers into open source software development.\nI chose KDE's Okular (it's a " +
    'document viewer, something like Adobe Reader), as even before GSoC I was a contributor ' +
    'there. My job was to improve the EPUB support and add audio/video rendering capabilities',
};
